<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import CreateSubscriber from '~/components/modal-windows/CreateSubscriber.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import SubscriptionForm from '~/enums/subscription-form'

const model = defineModel<boolean>()

function close (): void {
  model.value = false
}

async function openCreateSubscriberModalWindow (): Promise<void> {
  close ()

  await useModal({ component: CreateSubscriber, attrs: { subscriptionForm: SubscriptionForm.GreenFriday } }).open()
}
</script>

<template>
  <CustomModalWindow v-model="model">
    <template #title>
      О&nbsp;«Зелёной пятнице»
    </template>

    <p>«Зелёная пятница»&nbsp;— это самая большая распродажа iTAB, которая проходит всего 3&nbsp;дня в&nbsp;году. Более 2000&nbsp;товаров со&nbsp;скидками до&nbsp;70%!</p>

    <BasicButton color="green" :title="'Напомнить о\xA0старте'" @click="openCreateSubscriberModalWindow" />
  </CustomModalWindow>
</template>
