<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import SubscriptionInput from '~/components/form-elements/SubscriptionInput.vue'
import SubscriptionForm from '~/enums/subscription-form'
import ApiErrorHandler from '~/helpers/api-error-handler'
import RetailRocketApiService from '~/services/retail-rocket-api-service'

type Form = {
  email: string | null
}

const emptyForm: Form = { email: null }
const messages = {
  emailFieldCannotBeEmpty: 'Email не введён.',
  failedToSubscribe: 'Не удалось подписаться.',
  thanksForSubscribing: 'Спасибо за подписку!'
}

const { $apiHelper, $toast } = useNuxtApp()

const form = ref<Form>({ ...emptyForm })
const isSubscriberCreating = ref(false)

async function subscribe (): Promise<void> {
  const { email } = form.value
  const { emailFieldCannotBeEmpty, failedToSubscribe, thanksForSubscribing } = messages

  if (!email) {
    $toast.error(emailFieldCannotBeEmpty)

    return
  }

  isSubscriberCreating.value = true

  try {
    await $apiHelper.subscribers.createSubscriber(email, SubscriptionForm.MainPage)

    form.value = { ...emptyForm }

    $toast.success(thanksForSubscribing)

    RetailRocketApiService.setEmail(email)

    RetailRocketApiService.welcomeSequence(email)
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToSubscribe)
  }

  isSubscriberCreating.value = false
}
</script>

<template>
  <section>
    <div class="container">
      <div class="flex flex-col rounded-2xl bg-main-blue md:mx-0 md:flex-row md:rounded-3xl">
        <div class="px-4 pb-4 pt-5 md:w-1/2 md:p-10">
          <div class="mb-2 w-64 text-2xl font-medium text-white md:mb-5 md:w-96 md:text-4xl">
            Хотите быть в&nbsp;курсе скидок и&nbsp;акций?
          </div>

          <div class="text-sm text-white md:text-base">
            Подпишитесь на&nbsp;рассылку с&nbsp;новостями и&nbsp;скидками
          </div>
        </div>
        <div class="px-4 pb-5 md:w-1/2 md:p-10">
          <SubscriptionInput
            v-model="form.email"
            class="mb-3 md:mb-4"
            :is-subscribing="isSubscriberCreating"
            placeholder="Почта"
            @on-button-click="subscribe"
          />

          <Agreement icon-name="outlined/east" is-white />
        </div>
      </div>
    </div>
  </section>
</template>
