<script lang="ts" setup>
import { bannerCards } from '~/constants/banners'

const router = useRouter()
</script>

<template>
  <div>
    <div
      v-for="({ buttonText, description, name, path }, i) in bannerCards"
      :key="name"
      class="h-[174px] cursor-pointer rounded-3xl p-5 text-white"
      :class="i === 0 ? 'mb-4 bg-main-blue' : 'bg-[#fab042]'"
      @click="router.push(path)"
    >
      <div class="mb-2 flex h-[104px] flex-col justify-between">
        <div class="text-[23px] leading-6">
          {{ name }}
        </div>
        <div>
          {{ description }}
        </div>
      </div>
      <div class="font-medium">
        {{ buttonText }}
      </div>
    </div>
  </div>
</template>
