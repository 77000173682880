<script lang="ts" setup>
import CardWithImage from '~/components/cards/CardWithImage.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import helpers from '~/helpers'

const props = defineProps<{
  articlesData: any[]
  title: string
}>()

const path = Page.Articles

const router = useRouter()
</script>

<template>
  <section v-if="articlesData.length">
    <div class="container">
      <Heading element="h2" :path="path" :title="title" title-class="h1">
        <template #buttons>
          <BasicButton
            :aria-label="title"
            color="blue"
            icon="outlined/east"
            is-icon-only
            is-small
            @click="router.push(path)"
          />
        </template>
      </Heading>
    </div>

    <Gallery :options="constants.galleryOptions2">
      <CardWithImage
        v-for="x in articlesData"
        :key="x.id"
        class="max-4-slides swiper-slide"
        :data="x"
        :path="helpers.getArticlePath(x)"
      />
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
