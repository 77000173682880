<script lang="ts" setup>
import AdvantageCard from '~/components/cards/AdvantageCard.vue'
import Page from '~/enums/page'
import type { Advantage } from '~/types/advantages'

const advantages: Advantage[] = [
  {
    description: 'Отбираем лучшие аналоги любимых добавок, которые ищете\xA0вы или рекомендует специалист',
    iconName: 'advantages/hand-with-heart',
    path: Page.Products,
    title: '99% самых нужных БАД'
  },
  {
    description: 'Тщательно проверяем СГР и\xA0все декларации, гарантируем подлинность продукции',
    iconName: 'advantages/badge-check',
    path: Page.Products,
    title: 'Сертифицированная продукция'
  },
  {
    description: 'Быстрая доставка среди всех профессиональных маркетплейсов мира',
    iconName: 'advantages/truck',
    path: Page.Delivery,
    title: 'Более 86\xA0000 пунктов выдачи в\xA0России и\xA0за\xA0рубежом'
  },
  {
    description: 'Индивидуальный подбор БАД самостоятельно или после консультации со\xA0специалистом',
    iconName: 'advantages/seal-question',
    path: Page.Tests,
    title: 'Тесты и\xA0консультации'
  }
]
</script>

<template>
  <section>
    <div class="container">
      <h2 class="h1 mb-4">
        О&nbsp;нас
      </h2>

      <div class="flex flex-col md:flex-row md:space-x-4">
        <div
          class="
            mb-4 flex items-center rounded-2xl bg-main-green px-3 py-8 text-center text-[18px] font-medium text-white
            md:mx-0 md:mb-0 md:w-1/2 md:rounded-3xl md:px-8 md:text-4xl
          "
        >
          iTAB&nbsp;— качественные товары для здоровья, одобренные ведущими врачами России
        </div>
        <div class="flex flex-col space-y-3 md:w-1/2">
          <AdvantageCard v-for="x in advantages" :key="x.title" :advantage="x" />
        </div>
      </div>
    </div>
  </section>
</template>
